import React, { useState } from 'react';
import './GiftTableSection.css';
import giftImage from '../assets/images/LOGO-SIMAN.png';


const GiftTableSection = ({ giftTitle, giftDescription, giftAccount }) => {
  const cleanAccount = giftAccount.split(' ')[0]; // Get clean account number
  const [copied, setCopied] = useState(false); // Track copy status

  // Function to copy the clean account to clipboard
  const handleCopy = () => {
    navigator.clipboard.writeText(cleanAccount).then(() => {
      setCopied(true); // Show copied status
      setTimeout(() => setCopied(false), 2000); // Reset copy status after 2 seconds
    });
  };

  return (
    <section id="gifts" className="gift-table-section">
      <h2>{giftTitle}</h2>
      <div className="title-decor">
        <div className="line"></div>
        <div className="circle"></div>
        <div className="line"></div>
      </div>
      <p className="gift-description">{giftDescription}</p>
      <p className="gift-description">O ingresa al link y selecciona lo que desees obsequiarnos</p>
      <a href={giftAccount} target="_blank" rel="noopener noreferrer">
        <img src={giftImage} alt="Bodas Siman" className="gift-image" />
      </a>
    </section>
  );
};

export default GiftTableSection;
